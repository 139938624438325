@tailwind base;
@tailwind components;

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

@tailwind utilities;

// These classes will be used only on erb files
// If you want to use it in React files, please use the Button UI component
@layer components {
  .btn-primary {
    @apply cursor-pointer items-center px-4 py-2 border border-transparent leading-4 text-sm text-white font-bold rounded-md shadow-sm bg-sky-500 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 capitalize disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .btn-secondary {
    @apply cursor-pointer items-center px-4 py-2 border border-transparent leading-4 text-sm text-sky-500 font-bold rounded-md shadow-sm bg-sky-100 hover:bg-sky-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-700 capitalize disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .btn-tertiary {
    @apply cursor-pointer items-center px-4 py-2 border border-transparent leading-4 text-sm text-white font-bold rounded-md shadow-sm bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-700 capitalize disabled:opacity-50 disabled:cursor-not-allowed;
  }
}

.stripe-style-button {
  background: repeating-linear-gradient(
                  45deg,
                  #FEE2E2,
                  #FEE2E2 20px,
                  #DCFCE7 20px,
                  #DCFCE7 40px
  );
}

.stripe-style-button:hover {
  background: repeating-linear-gradient(
                  45deg,
                  #FCA5A5,
                  #FCA5A5 20px,
                  #86EFAC 20px,
                  #86EFAC 40px
  );
}
